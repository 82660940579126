import store from '@/store'
const title = store.getters.title
export const dateArr = [
  {
    title: '日期',
    dataIndex: 'date',
    width: 130,
    align: 'left',
    name: '日期',
    scopedSlots: { customRender: 'date' },
    fixed: true
  }
]
export const columns = [
  {
    dataIndex: 'income',
    width: 120,
    name: '预估收益',
    scopedSlots: { customRender: 'income', title: 'incomeTitle' },
    desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
    align: 'left'
  },
  {
    dataIndex: 'estimatedRevenueEcpm',
    width: 120,
    name: '预估eCPM',
    scopedSlots: { customRender: 'estimatedRevenueEcpm', title: 'estimatedRevenueEcpmTitle' },
    desc: `应用向${title}发送请求后，响应成功的占比`,
    align: 'left'
  },
  {
    dataIndex: 'request',
    scopedSlots: { customRender: 'request', title: 'requestTitle' },
    name: '流量请求',
    desc: `应用向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'requestFilledRate',
    scopedSlots: { customRender: 'requestFilledRate', title: 'requestFilledRateTitle' },
    name: '流量填充率',
    desc: `应用向${title}发送请求后，响应成功的占比`,
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'advRequest',
    name: '广告请求',
    scopedSlots: { customRender: 'advRequest', title: 'advRequestTitle' },
    desc: `${title}向广告平台发送请求的次数。一次流量请求可能触发多次广告请求`,
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'advRequestFilledRate',
    name: '广告填充率',
    scopedSlots: { customRender: 'advRequestFilledRate', title: 'advRequestFilledRateTitle' },
    desc: `${title}向广告平台发送请求后，返回成功的占比`,
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'impress',
    name: '展示',
    scopedSlots: { customRender: 'impress', title: 'impressTitle' },
    desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'impressRate',
    name: '展示率',
    scopedSlots: { customRender: 'impressRate', title: 'impressRateTitle' },
    desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/流量填充)`,
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'click',
    name: '点击',
    scopedSlots: { customRender: 'click', title: 'clickTitle' },
    desc: `${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`,
    width: 100,
    align: 'left'
  },
  {
    dataIndex: 'clickRate',
    name: '点击率',
    scopedSlots: { customRender: 'clickRate', title: 'clickRateTitle' },
    desc: `${title}统计的点击率，点击率=点击数/展示数`,
    width: 100,
    align: 'left'
  }
]

export const allDimesion = [
  {
    key: 'app_id',
    dataIndex: 'appName',
    isWrap: false,
    name: '应用',
    checked: false,
    prop: 'appName',
    width: 120
  },
  {
    key: 'place_id',
    dataIndex: 'placeName',
    isWrap: false,
    name: '广告位',
    checked: false,
    prop: 'placeName',
    width: 180
  },
  {
    key: 'position_id',
    dataIndex: 'positionName',
    isWrap: false,
    name: '广告样式',
    checked: false,
    prop: 'positionName',
    width: 100
  },
  {
    key: 'os',
    dataIndex: 'os',
    isWrap: false,
    name: '操作系统',
    checked: false,
    prop: 'os',
    width: 80
  },
  {
    key: 'group_id',
    dataIndex: 'groupName',
    isWrap: false,
    name: '流量分组',
    checked: false,
    prop: 'groupName',
    width: 120
  },
  {
    key: 'channel',
    dataIndex: 'channel',
    isWrap: false,
    name: '渠道',
    checked: false,
    prop: 'channel',
    width: 90
  },
  // {
  //   key: 'plat_id',
  //   dataIndex: 'platName',
  //   isWrap: false,
  //   name: '广告平台',
  //   checked: false,
  //   prop: 'platName',
  //   width: 100
  // },
  // {
  //   key: 'ad_source_id',
  //   dataIndex: 'sourceName',
  //   isWrap: false,
  //   name: '广告源',
  //   checked: false,
  //   prop: 'sourceName',
  //   width: 120
  // },
  {
    key: 'sdk_version',
    dataIndex: 'sdkVersion',
    isWrap: false,
    name: 'SDK版本',
    checked: false,
    prop: 'sdkVersion',
    width: 80
  },
  {
    key: 'app_version',
    dataIndex: 'appVersion',
    isWrap: false,
    name: '应用版本',
    checked: false,
    prop: 'appVersion',
    width: 100
  }
]
export const dimensionArr = [
  {
    dataIndex: 'impressRequestRate',
    width: 120,
    name: '展请率',
    scopedSlots: { customRender: 'impressRequestRate', title: 'impressRequestRateTitle' },
    align: 'left'
  },
  {
    name: '竞价数',
    dataIndex: 'askPriceRequest',
    scopedSlots: { title: 'askPriceRequestTitle' },
    width: 120,
    align: 'left'
  },
  {
    name: '竞价响应率',
    dataIndex: 'askPriceFilledRate',
    scopedSlots: { customRender: 'askPriceFilledRate', title: 'askPriceFilledRateTitle' },
    width: 120,
    align: 'left'
  },
  {
    dataIndex: 'askPrice',
    name: '竞价响应eCPM',
    scopedSlots: { title: 'askPriceTitle' },
    width: 140,
    align: 'left'
  },
  {
    dataIndex: 'advResponse',
    name: '广告填充',
    title: '广告填充',
    scopedSlots: { customRender: 'advResponse' },
    width: 140,
    align: 'left'
  },
  {
    dataIndex: 'requestDau',
    name: '人均请求',
    title: '人均请求',
    scopedSlots: { customRender: 'requestDau' },
    width: 140,
    align: 'left'
  },
  {
    dataIndex: 'clickDau',
    name: '人均点击',
    title: '人均点击',
    scopedSlots: { customRender: 'clickDau' },
    width: 140,
    align: 'left'
  }
]
